import "../css/Project.css";
import "../css/Topic.css";
import{FaGithub} from 'react-icons/fa';
import {BsArrowUpRight} from 'react-icons/bs';
import {HiLink} from 'react-icons/hi';
import Topics from "./Topics";
import {useEffect, useState} from 'react';
import { CLIENT_ID, CLIENT_SECRET } from "../Constants";


function ProjectRight(props)
{
    const [topics, setTopics] = useState(()=>[]);
    const [languages, setLanguages] = useState([]);


    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/vnd.github.mercy-preview+json");

    var requestOptions = 
    {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    useEffect(()=>{
        
        fetch("https://api.github.com/repos/Servatom/"+props.data.title+`/topics?client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}`, requestOptions)
            .then(response => response.json())
            .then(result => setTopics(result.names))
            .catch(error => console.log('error', error));     

        fetch("https://api.github.com/repos/Servatom/"+props.data.title+`/languages?client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLanguages(Object.keys(result).slice(0,3))
            })
            .catch(error => console.log('error', error));

    },[])
    
    const imgUrl= "https://assets.servatom.com/"+props.data.title+"/logo.png";

    return(
        
        <div className="oneProject">
            <div className="project-content-left">
                <h3>{props.data.title}</h3>
                <p>
                    {props.data.description}
                    <br/>
                    <div className="languageList">
                        {
                            languages.map((language)=>{
                            return(<p className="language">{language}</p>)
                            })
                        }
                    </div>
                    <div className="topics topics-right">
                    {
                        topics.map((topic)=>{
                            return(<Topics topic={topic}/>)
                        })
                    }
                    </div>
                    <div className="url">
                        <a href={props.data.url}><FaGithub className="icon"/>Github <BsArrowUpRight/></a>
                        {
                            props.data.homepage && props.data.homepage.length?
                            <a href={props.data.homepage}><HiLink className="icon"/>Website<BsArrowUpRight/></a>
                            :null
                        }
                    </div>
                </p>
            </div>
            <div className=" number">
                {/* #{props.number} */}
                <img src={imgUrl} className="project-img"/>
            </div>
        </div>
    );
}

export default ProjectRight;