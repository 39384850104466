import "../css/Header.css";
import logo from '../media/logo.png';

const Heading =()=>
{
    return(
        <div className="header">
            <div className="logoContainer">
                <img src={logo} className="logo"/>
                <h1>Servatom</h1>
            </div>
            <div className="Subheading">
                {/* <div className="underline"></div> */}
                <h3>Building something exciting!</h3>
            </div>  
        </div>
    );
}

export default Heading;